<template>
  <div class="shop-info">
    <!-- 表单提交 -->
    <el-form
      :model="params"
      :rules="rules"
      ref="form-ref"
      inline
      label-width="100px"
    >
      <section class="form-main">
        <!-- 头部 -->
        <div class="shop-top">
          <div class="top-left">
            <el-form-item label="商户名称" prop="shopName">
              <el-input v-model.trim="params.merchantName" disabled></el-input>
            </el-form-item>
            <el-form-item label="门店名称" prop="shopName">
              <el-input
                v-model.trim="params.shopName"
                maxlength="16"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item label="门头图片" prop="shopFileUrl">
            <div class="upload-btn" @click="onUpload(1)">
              <img
                v-if="params.shopFileUrl"
                :src="params.shopFileUrl"
                class="upload-img"
              />
              <i v-else class="el-icon-plus uploader-icon"></i>
            </div>
          </el-form-item>
        </div>
        <!-- 中间 -->
        <div class="shop-mid">
          <el-form-item label="门店区域" prop="districtId">
            <el-cascader
              v-model="address"
              :options="cityOpt"
              :props="{ expandTrigger: 'hover' }"
            ></el-cascader>
          </el-form-item>
          <div>
            <el-autocomplete
              popper-class="my-autocomplete"
              v-model="params.shopAddress"
              :fetch-suggestions="querySearch"
              placeholder="请填写详细地址"
              @select="onSelect"
              clearable
            >
              <template slot-scope="{ item }">
                <div class="name">{{ item.value }}</div>
                <span class="addr" v-if="item.address.length">{{
                  item.address
                }}</span>
              </template>
            </el-autocomplete>
          </div>
        </div>
        <div class="map-tips">请拖动标记到正确的位置</div>
        <!-- 地图 -->
        <div id="container"></div>
        <!-- 商家电话 -->
        <el-form-item label="商家电话" prop="shopPhone">
          <el-input
            type="number"
            v-model="params.shopPhone"
            placeholder="请填入商家电话"
          ></el-input>
        </el-form-item>
        <!-- 底部 -->
        <div class="shop-foot">
          <el-form-item label="营业时间" prop="businessHoursStart">
            <el-time-select
              placeholder="起始时间"
              v-model="params.businessHoursStart"
              :editable="false"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30',
              }"
            >
            </el-time-select>
          </el-form-item>
          <el-form-item prop="businessHoursEnd">
            <el-time-select
              placeholder="结束时间"
              v-model="params.businessHoursEnd"
              :editable="false"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30',
              }"
            >
            </el-time-select>
          </el-form-item>
          <span class="tips"
            >tips: 默认显示时间00:00~00:00为24小时全天营业</span
          >
        </div>
        <div class="shop-foot">
          <div>
            <el-form-item label="店铺装修员" prop="testName">
              <el-input
                v-model.trim="params.testName"
                maxlength="16"
                placeholder="请填入店铺装修员"
              ></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="装修员手机号" prop="testMobile">
              <el-input
                v-model.trim="params.testMobile"
                maxlength="11"
                placeholder="请填入装修人员手机号"
              ></el-input>
            </el-form-item>
          </div>
          <span class="tips"
            >tips: 请输入与该员工微信号绑定的手机号便于门店上线前测试流程</span
          >
        </div>
        <div>
          <el-form-item label="社群助手微信二维码" class="switch">
            <div class="upload-btn" @click="onUpload(2)">
              <img
                v-if="params.shopGroupQrCodeUrl"
                :src="params.shopGroupQrCodeUrl"
                class="upload-img"
              />
              <i v-else class="el-icon-plus uploader-icon"></i>
            </div>
          </el-form-item>
        </div>

        <!-- 店铺停业 -->
        <el-form-item label="店铺停业" prop="closeDownStatus" class="switch">
          <el-switch
            v-model="params.closeDownStatus"
            active-color="#F9D038"
            inactive-color="#999"
            :active-value="0"
            :inactive-value="1"
          >
          </el-switch>
        </el-form-item>
        <!-- 停业起止日期 -->
        <el-form-item
          label="停业起止日期"
          label-width="120px"
          prop="closeDownEnd"
          v-if="!params.closeDownStatus"
        >
          <el-date-picker
            v-model="dateToDate"
            type="daterange"
            :editable="false"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <!--视频配置-->
        <div>
          <el-form-item label="视频配置" label-width="100px">
            <div>
              <div
                style="display: flex; margin-bottom: 10px"
                v-for="(item, index) in params.wechatVideoList"
                :key="index"
              >
                <div style="width: 100px">{{ item.configName }}</div>
                <el-button
                  size="small"
                  icon="el-icon-edit"
                  type="primary"
                  @click="goClick(1, item, index)"
                ></el-button>
                <el-button
                  size="small"
                  icon="el-icon-delete"
                  type="danger"
                  @click="goClick(0, item, index)"
                ></el-button>
              </div>
              <el-button size="mini" plain type="primary" @click="goOpenSetting"
                >添加配置</el-button
              >
            </div>
          </el-form-item>
        </div>
      </section>
      <section>
        <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
      </section>
    </el-form>
    <!-- 图片上传组件 -->
    <UpLoad @fileIds="getFileIds" ref="up" :fileType="1"></UpLoad>
    <el-dialog
      :title="settingTitle"
      :visible.sync="settingVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form :model="videoObj" :rules="rules" ref="videoObj-ref">
        <el-form-item
          style="width: 100%"
          label="视频名称"
          label-width="120px"
          prop="configName"
        >
          <el-input
            v-model.trim="videoObj.configName"
            maxlength="4"
            placeholder="请输入4个字以内的名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          style="width: 100%"
          label="视频id"
          label-width="120px"
          prop="videoId"
        >
          <el-input
            v-model.trim="videoObj.videoId"
            placeholder="请输入视频id"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <section>
        <div class="form-footer">
          <div>
            <el-button type="primary" @click="goSaveSetting('videoObj-ref')"
            >确定</el-button
          >
          </div>

          <div class="red">请先上传视频至“壹启玩”视频号，再联系平台获取视频id</div>
        </div>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import { pickerOptions } from "@/db/objs";
import { rules } from "@/db/rules";
import { getShopInfo, getShopEdit } from "@/api/shop/shopInfo";
import UpLoad from "@/components/upload";
import { getCityList } from "@/api/common";
import { getFileList } from "@/api/common";
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  components: { UpLoad },
  data() {
    return {
      rules,
      pickerOptions,
      cityOpt: [], //省市区三级列表
      address: [], //省市区集合
      dateToDate: "", //停业日期
      mapInfo: [], //地图信息
      params: {
        shopId: "", //门店ID
        merchantName: "", //商戶名称
        shopName: "", //门店名称
        shopFileId: "", //图片ID
        shopFileUrl: "", //图片路径
        provinceId: "", //省
        cityId: "", //市
        districtId: "", //区
        shopLat: "", //纬度
        shopLgt: "", //经度
        shopAddress: "", //门店地址
        businessHoursStart: "", //营业开始时间段
        businessHoursEnd: "", //营业结束时间段
        shopPhone: "", //商家电话
        closeDownStatus: 0, //店铺停业
        closeDownStart: "",
        closeDownEnd: "",
        testName: "",
        testMobile: "",
        mchNo: "",
        shopGroupQrCode: "", //门店二维码文件id
        shopGroupQrCodeUrl: "", //门店二维码文件iurl
        wechatVideoList: [],
        wechatVideoDTOList: [],
      },
      // 地图
      map: null,
      restaurants: [],
      imgType: 1,
      settingVisible: false,
      settingTitle: "",
      videoObj: {
        configId: "",
        configName: "",
        videoId: "",
      },
      selectIndex: 0,
    };
  },
  watch: {
    address(val) {
      this.params.provinceId = val[0];
      this.params.cityId = val[1];
      this.params.districtId = val[2];
    },
    dateToDate(val) {
      if (val) {
        this.params.closeDownStart = val[0];
        this.params.closeDownEnd = val[1];
      } else {
        this.params.closeDownStart = "";
        this.params.closeDownEnd = "";
      }
    },
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.params.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getShopInfo(); //【请求】店铺详情
    this.getCityList(); //【请求】省市区三级联动
    this.initMap(120.162142, 30.278988, 12);
  },
  methods: {
    // 点击  type 1:编辑 0:删除
    goClick(type, item, index) {
      this.selectIndex = index;
      console.log("item", this.params);
      this.params;
      if (type == 1) {
        this.settingTitle = "编辑配置";
        this.settingVisible = true;
        this.videoObj = {
          configId: item.configId,
          configName: item.configName,
          videoId: item.videoId,
        };
      } else {
        this.params.wechatVideoList.splice(
          this.params.wechatVideoList.indexOf(item),
          1
        );
      }
    },
    // 打开添加配置
    goOpenSetting() {
      this.settingTitle = "新增配置";
      this.videoObj = {
        configId: "",
        configName: "",
        videoId: "",
      };
      this.settingVisible = true;
    },
    // 【请求】店铺详情
    getShopInfo() {
      let data = {
        shopId: this.params.shopId,
      };
      getShopInfo(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.params = res.data;
          this.address = [
            Number(this.params.provinceId),
            Number(this.params.cityId),
            Number(this.params.districtId),
          ];
          if (res.data.closeDownStart && res.data.closeDownEnd) {
            this.dateToDate = [res.data.closeDownStart, res.data.closeDownEnd];
          }
          if (res.data.shopLgt && res.data.shopLat) {
            this.initMap(res.data.shopLgt, res.data.shopLat, 18);
          }

          console.log("回显", this.params);
        }
      });
    },

    // 【请求】省市区三级联动
    getCityList() {
      let data = this.tableParams;
      getCityList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.cityOpt = res.data;
        }
      });
    },

    // 【渲染】初始化地图
    initMap(lgt, lat, zoom) {
      AMapLoader.load({
        key: "dd17bf659be63c13a39b39dd3d2a8a54", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.AutoComplete", "AMap.PlaceSearch", "AMap.AutoComplete"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map("container", {
          //设置地图容器id
          viewMode: "3D", //是否为3D地图模式
          zoom: zoom, //初始化地图级别
          center: [lgt, lat], //初始化地图中心点位置
        });
        let marker = new AMap.Marker({
          position: new AMap.LngLat(lgt, lat), //参数为经纬度
          draggable: true,
        });
        marker.on("dragging", this.showInfoM);
        this.map.clearMap(); // 清除所有覆盖物（点标志）
        this.map.add(marker); // 添加点标志
      });
    },

    // 【监听】拖拽
    showInfoM: _.debounce(function (e) {
      this.params.shopLat = e.lnglat.lat;
      this.params.shopLgt = e.lnglat.lng;
    }, 500),

    querySearch(queryString, cb) {
      this.map.plugin("AMap.AutoComplete", () => {
        let autoOptions = {
          city: "全国", //city 限定城市，默认全国
        };
        let autoComplete = new AMap.AutoComplete(autoOptions);
        autoComplete.search(queryString, (status, res) => {
          if (res.tips) {
            var restaurants = JSON.parse(
              JSON.stringify(res.tips)
                .replace(/name/g, "value")
                .replace(/district/g, "address")
            );
            let results = queryString
              ? restaurants.filter(this.createFilter(queryString))
              : restaurants;
            cb(results);
          } else {
            cb([]);
          }
        });
      });
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },

    // 【监听】选择
    onSelect(item) {
      this.params.shopLgt = item.location[0];
      this.params.shopLat = item.location[1];
      if (item.location[0] && item.location[1]) {
        this.initMap(item.location[0], item.location[1], 18);
      }
    },

    // 【请求】保存门店信息
    getShopEdit() {
      let data = this.params;
      this.params.wechatVideoDTOList = this.params.wechatVideoList;
      getShopEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getShopInfo();
        }
      });
    },

    // 【请求】获取文件信息
    getFileList(fileIds) {
      let data = {
        fileIds,
      };
      getFileList(data).then((res) => {
        if (res.isSuccess == "yes") {
          if (this.imgType == 1) {
            this.params.shopFileId = res.data[0].attId;
            this.params.shopFileUrl = res.data[0].url;
          } else {
            this.params.shopGroupQrCode = res.data[0].attId;
            this.params.shopGroupQrCodeUrl = res.data[0].url;
          }
        }
      });
    },

    // 【监听】文件上传
    onUpload(val) {
      this.imgType = val;
      this.$refs.up.onUpload();
    },

    // 【监听】获取上传文件ID集合
    getFileIds(fileIds) {
      this.params.picId = fileIds;
      this.getFileList(fileIds);
    },
    // 本地保存配置
    goSaveSetting(formName) {
      this.$refs[formName].validate((valid, error) => {
        if (valid) {
          if (this.params.wechatVideoList == null) {
            this.params.wechatVideoList = [];
          }
          if (this.settingTitle == "新增配置") {
            this.params.wechatVideoList.push(this.videoObj);
          } else {
            this.params.wechatVideoList[this.selectIndex] = this.videoObj;
          }
          this.videoObj = {
            configId: "",
            configName: "",
            videoId: "",
          };
          this.selectIndex = "";
          this.settingVisible = false;
        } else {
          return false;
        }
      });
    },
    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid, error) => {
        if (valid) {
          this.getShopEdit();
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-info {
  padding: 0.15rem;
  box-sizing: border-box;
}

.upload-btn {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
}

.upload-btn:hover {
  border-color: #409eff;
}

.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  text-align: center;
}

.upload-img {
  width: 1rem;
  height: 1rem;
  display: block;
}

.shop-top {
  display: flex;
}

.top-left {
  display: flex;
  flex-direction: column;
  margin-right: 0.15rem;
}

.shop-mid {
  display: flex;
}

.map-tips {
  color: red;
  margin-bottom: 0.1rem;
  font-size: 0.12rem;
}

// 地图
#container {
  padding: 0;
  margin-bottom: 0.15rem;
  border: 1px dashed #d9d9d9;
  box-sizing: border-box;
  width: 6rem;
  height: 3.5rem;
}

.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}

.tips {
  color: #999;
  font-size: 0.12rem;
  display: flex;
  margin-left: 100px;
  margin-bottom: 0.15rem;
}
.form-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .red {
    color: red;
    margin-top: 10px;
  }
}
</style>

<style lang="scss">
.shop-foot {
  .el-form-item__content {
    width: 2.2rem !important;
  }
}

.switch {
  .el-form-item__content {
    width: 0.5rem !important;
  }
}
</style>