import request from '@/utils/request'
// 门店详情
export function getShopInfo(data) {
  return request('post', '/apm/shop/getShopInfo', data)
}

// 编辑门店
export function getShopEdit(data) {
  return request('post', '/apm/shop/info/update', data)
}

// 预约订单配置 详情
export function getOrderConfig(data) {
  return request('get', '/apm/reservation/order/config/getConfig', data)
}

// 预约订单配置 保存
export function goSaveOrderConfig(data) {
  return request('post', '/apm/reservation/order/config/update/config', data)
}